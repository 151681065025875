<template>
  <div class="doctor">
    <!-- 背景 -->
    <div class="EMS_bg">
      <div class="EMS_bg_top">
        <the-nav></the-nav>
      </div>
      <div class="EMS_bg_bottom w1200">
        <div class="EMS_bg_bottom_left">
          <div class="EMS_bg_bottom_logo">
            <img src="../../assets/imgs/img-logo-c.png" alt="储电医生" />
          </div>
          <h1>24小时在线的电池安全服务</h1>
          <h6>AI人工智能监控电池数据波动，发现“生病”的电池</h6>
          <div class="yuyue_btn" @click="booking">预约体验</div>
        </div>
        <div class="EMS_bg_bottom_right"></div>
      </div>
    </div>
    <!-- 0 -->
    <div class="EMS_box bg_white">
      <div class="EMS_box_con0 w1200">
        <div class="zero_title">针对储能电池算法优化</div>
        <div class="common_title align_center">
          <span class="cn_title">常见异常全面捕获</span>
          <div class="en_title">同时给出运维建议，指导运维人员妥善及时处理</div>
        </div>
        <div class="grid">
          <div
            class="grid_item"
            v-for="(item, index) in suanfa"
            :key="'suanfa' + index"
          >
            <div class="svg">
              <svg class="svg_img" aria-hidden="true">
                <use :xlink:href="`#${item.svg}`"></use>
              </svg>
            </div>

            <div class="bottom">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 -->
    <div class="EMS_box bg_image">
      <div class="EMS_box_con1 w1200">
        <div class="right" style="margin-right: 108px">
          <div class="common_title">
            <span class="cn_title" style="color: #fff">安全状态 尽在掌握</span>
          </div>
          <div
            class="right_description"
            style="color: rgba(255, 255, 255, 0.85)"
          >
            定期自动诊断，推送诊断报告
            <br />
            手动开启立即诊断，实时了解电池状态
            <br />
            自由调取诊断记录和详细
          </div>
        </div>
        <div class="left" style="margin-right: 0">
          <img src="../../assets/imgs/img-doctor-picture-a.png" alt="安全" />
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="EMS_box">
      <div class="EMS_box_con1 w1200">
        <div class="left">
          <img src="../../assets/imgs/img-doctor-picture-b.png" alt="安全" />
        </div>
        <div class="right">
          <div class="common_title">
            <span class="cn_title">诊断不是目的，延寿才是</span>
          </div>
          <div class="right_description">
            及时发现异常电池，实时保养和替换
            <br />
            为储能系统延寿，增加电站营收
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <the-footer></the-footer>
  </div>
</template>

<script>
export default {
  name: "doctor",
  data() {
    return {
      suanfa: [
        { name: "SOC不一致性偏高", svg: "a-icon-doctor-a1x" },
        { name: "SOC不一致性偏低", svg: "a-icon-doctor-b1x" },
        { name: "电容量不足", svg: "a-icon-doctor-c1x" },
        { name: "电池微短路", svg: "a-icon-doctor-d1x" },
        { name: "电连接异常", svg: "a-icon-doctor-e1x" },
        { name: "BMS采集故障", svg: "a-icon-doctor-f1x" },
      ],
    };
  },
  methods: {
    booking() {
      // this.$store.commit("setState", { visible: true });
      window.open(
        `https://www.fqixin.cn/fsh5/smart-form/index.html?cardId=6570209edf8ff0034dc6d51a`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
.doctor {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .EMS_bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 642px;
    background-color: #090c1b;
    &_top {
      display: flex;
      justify-content: center;
      height: 80px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
    }
    &_bottom {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-top: 48px;
      &_logo {
        width: 183px;
        height: 72px;
        margin-top: 48px;
        margin-bottom: 32px;
      }
      h1 {
        font-size: 40px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        margin-bottom: 16px;
      }
      h6 {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: rgba(255, 255, 255, 0.45);
        margin-bottom: 80px;
      }
      .yuyue_btn {
        width: 187px;
        height: 56px;
        line-height: 56px;
        background-color: rgba(0, 198, 153, 0.15);
        border: 1px solid rgba(0, 198, 153, 0.45);
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #00c699;
        cursor: pointer;
        &:hover {
          background-color: #00c699;
          color: #ffffff;
        }
      }
      &_right {
        width: 697px;
        height: 440px;
        background-image: url("../../assets/imgs/img-product-c.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  .bg_white {
    background-color: #ffffff;
  }
  .bg_gray {
    background-color: #f4f5f7;
  }
  .bg_image {
    background-image: url("../../assets/imgs/img-ph-blue-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .EMS_box {
    padding: 88px 0;
    .common_title {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 34px;
      .cn_title {
        font-size: 30px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .en_title {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
        margin-top: 8px;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        content: "";
        display: block;
        width: 210px;
        height: 2px;
        background-color: #e6e6e6;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        content: "";
        display: block;
        width: 48px;
        height: 2px;
        background-color: #00c699;
      }
    }
    .align_center {
      align-items: center;
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &_con0 {
      padding: 88px 0 96px 0;
      border-radius: 4px;
      .zero_title {
        width: 100%;
        height: 168px;
        line-height: 168px;
        margin-bottom: 88px;
        font-size: 30px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        background-image: url("../../assets/imgs/img-doctor-data.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 217px);
        grid-gap: 8px 8px;
        margin-top: 48px;
        &_item {
          background-color: #f4f5f7;
          padding: 24px;
          .svg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 16px auto 0 auto;
            &_img {
              width: 56px;
              height: 56px;
            }
          }
          .bottom {
            margin-top: 32px;
            padding-top: 16px;
            border-top: 1px solid #e6e6e6;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
    &_con1 {
      display: flex;
      justify-content: center;
      .left {
        width: 496px;
        height: 660px;
        // border: 1px solid red;
        margin-right: 108px;
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 394px;
        margin-top: 146px;
        &_description {
          margin-top: 40px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
}
</style>
